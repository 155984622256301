import { gql } from '@apollo/client';

export const INSERT_MAILER_MUTATION = gql`
  mutation insert_mailer($mailer_insert_input: [mailer_insert_input!]!) {
    insert_mailer(objects: $mailer_insert_input) {
        returning {
            mailer_id
            email
        }
    }
}`;
