import "./styles/App.css"
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { PrivacyPolicy } from "./components/privacy-policy.js";
import { Home } from "./components/home.js";
import { MainProvider } from "./contexts/main-context";
import { ApolloProvider } from '@apollo/client';
import { createApolloClient } from "./scripts/create-apollo-client/create-apollo-client";

const App = () => {

  const client = createApolloClient()

  return (
    <ApolloProvider client={client}>
      <MainProvider>
        <Router>
          <Routes>
            <Route path={"/"} element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes >
        </Router >
      </MainProvider>
    </ApolloProvider>
  )
}

export default App
