import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { config } from '../../config/config';

export const createApolloClient = (authToken) => {
    return new ApolloClient({
        link: new HttpLink({
            uri: config.graphqlEndpoint,
            headers: {
                // 'Authorization': `Bearer ${authToken}`,
                'x-hasura-admin-secret': config.adminSecret,
            },
        }),
        cache: new InMemoryCache(),
    });
};