/*
* Generally, components should not contain internal state/logic!
* Generally, we want all state/logic variables to be in the screen-functions.ts files so we have access to them all in one place.
* This is because we want to be able to easily change the state/logic of the screen in one place.
* It is okay to include internal state/logic if multiple instances of the component are needed on a single screen.
* Also worth mentioning: you may choose to use the presets file to create new variations of this component.
* Don't forget to add all props in the interface file!
*/

import React, { useContext, useState } from "react"
import "../styles/App.css"
import { Footer } from "./footer";
import { Header } from "./header";
import { MainContext } from "../contexts/main-context";
import { globalStyles } from '../styles/styles';
import { Row, Col } from 'react-bootstrap'

/**
 * Describe the new component here...
 */
export const Home = () => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState('')
    const { insertMailerMutation } = useContext(MainContext)

    const onSubmit = (data) => {
        setIsFormSubmitted(true)
        insertMailerMutation({
            variables: {
                mailer_insert_input: [{
                    email: email,
                    first_name: firstName,
                    city: city,
                }]
            }
        })
        setTimeout(() => {
            setFirstName('')
            setEmail('')
            setCity('')
            setIsFormSubmitted(false)
        }, 6000);
    };
    return (
        <div className={"App"}>
            <Header />
            <Row className="d-flex justify-content-center align-items-center" style={{ marginLeft: 10, marginRight: 10 }} >
                <Col xs={12} sm={12} md={3} lg={3} >
                    <form onSubmit={(event) => { event.preventDefault(); onSubmit(event.data) }} style={{ marginTop: '2%' }}>
                        {isFormSubmitted ?
                            <>
                                <p style={{ fontSize: 50, }}>✅</p>
                                <p style={{ color: "#ffffff", fontSize: 20, fontWeight: 'bold', marginTop: '-20px' }}>{`Submitted. Thank you!`}</p>
                                <p style={{ color: "#ffffff", fontSize: 20, fontWeight: 'bold' }}>{`We will keep you informed.`}</p>
                                <p style={{ color: "#f7e2ad", fontSize: 20, fontWeight: 'bold' }}>{`Teachers Lounge`}</p>
                            </>
                            :
                            <>
                                <p style={{ color: "#ffffff", fontSize: 20, fontWeight: 'bold' }}>Show Your Interest</p>
                                <p style={{ color: "#ffffff", fontSize: 16 }}>Sign Up for Our Email List</p>
                                <br />
                                <input
                                    placeholder="👤 First Name"
                                    type={'text'}
                                    value={firstName || ''}
                                    onChange={(e) => setFirstName(e.target.value)}
                                />
                                <br />
                                <input
                                    placeholder="✉️ Email"
                                    type={'text'}
                                    value={email || ''}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                <br />
                                <input
                                    placeholder="📍 City"
                                    type={'text'}
                                    value={city || ''}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                                <br />
                                <input type="submit" />
                            </>
                        }
                    </form>
                </Col>
                <Col xs={12} sm={12} md={9} lg={9}>
                    <div className={"centerEverything"} style={{ ...globalStyles.blackBox }}>
                        <div style={{ padding: 5, borderRadius: 10 }}>
                            {`Introducing Teachers Lounge, the growing social network for educators. Available on both iOS & Android, this app is designed exclusively for teachers & education administrators.

With Teachers Lounge, educators have the opportunity to connect with their peers, share resources and knowledge, and rate and review schools in a private, secure environment. The app's user-friendly interface makes it easy for teachers to share posts, images, and links, as well as engage in real-time chat with other educators.

One of the key features of Teachers Lounge is the ability for educators to rate and review schools. This valuable tool allows teachers to share their experiences and provide insights into the quality of education provided by different schools. This feature makes it easy for educators to make informed decisions about where to work, and for education administrators to identify areas for improvement.

Teachers Lounge is the ultimate tool for educators looking to connect, collaborate, and learn from their peers. Its exclusive access to teachers and education administrators ensures a safe, supportive environment where educators can share and learn without fear of judgment. Download Teachers Lounge today and join the conversation!`.split('\n').filter((word) => word !== '').map((phrase, phraseIndex) => (
                                <p key={`index${phraseIndex}`} className="comingSoon" style={{ color: phraseIndex % 2 ? '#f7e2ad' : "#ffffff", fontSize: 22, fontWeight: 'bold' }}>
                                    {phrase}
                                </p>
                            ))}
                        </div>
                    </div>
                </Col>
            </Row>
            <Footer />
        </div >
    );
};
